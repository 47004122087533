// center map coords
var lat = 56.4572091;
var lng = -2.9784761;
// consts
var markers = [];
var months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC"
];
var PRCP = "PRCP";
var ETpot = "ETpot";
var ET = "ET";
var selectLocationText = "";
var map;

var formVariables = {
  speiclass: "N",
  stones: 1.0,
  plotLength: 1,
  plotWidth: 1,
  irrigations: {
    D: {
      rainfall: {},
      potential: {},
      transpiration: {}
    },
    N: {
      rainfall: {},
      potential: {},
      transpiration: {}
    },
    W: {
      rainfall: {},
      potential: {},
      transpiration: {}
    }
  },
  locationLat: "",
  locationLong: ""
};

function showWizardStep(id, event) {
  var wizardStepContainer = document.getElementById(id);
  if (wizardStepContainer) {
    var wizardSteps = [].slice.call(document.querySelectorAll(".wizard__step"));
    if (wizardSteps.length) {
      wizardSteps.forEach(function(step, index) {
        step.setAttribute("aria-hidden", true);
      });
    }
    wizardStepContainer.removeAttribute("aria-hidden");
    event.preventDefault();
  }
}

function checkCanSelectCoords() {
  if (formVariables.speiclass !== null && formVariables.stones !== null) {
    map.addListener("click", clickHandler);
  }
}

function selectSpeiClass(w) {
  formVariables.speiclass = w.value;
  checkCanSelectCoords();
  calculateResults();
}

function selectStones(w) {
  formVariables.stones = w.value;
  checkCanSelectCoords();
}

function clearMarkers() {
  // Clear out the old markers.
  markers.forEach(function(marker) {
    marker.setMap(null);
  });
  markers = [];
}

function addMapMarker(place) {
  clearMarkers();

  markers.push(
    new google.maps.Marker({
      map: map,
      position: place.geometry.location
    })
  );
}

function setLocationTexts() {
  var locationTextTargets = [].slice.call(
    document.querySelectorAll("[data-location-text='true']")
  );
  if (locationTextTargets.length) {
    locationTextTargets.forEach(function(target, index) {
      target.innerText =
        selectLocationText !== "" ? selectLocationText : "Selected location111";
    });
  }
}

async function clickHandler(e) {
  var lat = e.latLng.lat();
  var lng = e.latLng.lng();

  var latlng = { lat, lng };
  var geocoder = new google.maps.Geocoder();

  geocoder.geocode({ location: latlng }, function(results, status) {
    if (status === "OK") {
      if (results[0]) {
        selectLocationText = results[0].formatted_address;
        setLocationTexts();
      }
    }
  });

  addMapMarker({
    geometry: {
      location: e.latLng
    }
  });

  updatePlotSize();

  try {
    var nearest = await getNearest(lng, lat).catch(e => {
      console.error(e);
      throw e;
    });

    if (nearest.length) {
      enableCalculateButton();
      formVariables.locationLat = lat;
      formVariables.locationLong = lng;
    } else {
      return noResults();
    }
    window.plots = nearest;

    //.irrigations[irrigation]
    // would need to do the above filter
    formVariables.irrigations.D = filterData('D');
    formVariables.irrigations.N = filterData('N');
    formVariables.irrigations.W = filterData('W');

  } catch (ex) {
    console.error(ex);
  }
}

function filterData(what) {
  const d = window.plots.filter(p => p.SPEICLS == what);
  const rainfall = collateMonths(d);
  //  const potential = collateMonths(d.filter(n => n.Variable === ETpot));
  // const transpiration = collateMonths(d.filter(n => n.Variable === ET));
  return {
    rainfall,
    //potential,
    //transpiration
  };
}

function enableCalculateButton() {
  var calculateButton = document.getElementById("calculate");
  calculateButton.disabled = false;
  calculateButton.addEventListener("click", function(event) {
    calculateResults(event);
    setCurrentMonthOnResultsScreen();
    updatePlotSize();
    setPlotSizeTexts();
    showWizardStep("step-3", event);
  });
}

function setCurrentMonthOnResultsScreen() {
  var d = new Date();
  var n = d.getMonth();
  document
    .getElementById(months[n].toLowerCase())
    .classList.add("is-current-month");
}

//Irrigation water need (mm or l/m2) = IF ETmon > PRCPmon THEN ETPOTmon – ETmon ELSE 0
function calculateMonthsIrrigation(rainfall, potential, ET) {
  var reqWater = months.map(m => {
    //var req = ET[m] > rainfall[m] ? potential[m] - ET[m] : 0;
    var req = rainfall[m];
    var plotSize = formVariables.plotLength * formVariables.plotWidth;
    var irrigation = req * plotSize;
    var monthResult = document.getElementById(m.toLowerCase());
    monthResult.querySelector(
      ".results__item__irrigation"
    ).innerText = irrigation.toFixed(2);
    req > 0
      ? monthResult.classList.add("has-irrigation")
      : monthResult.classList.remove("has-irrigation");
  });
}

function backToStep1(event) {
  resetIrrigationControlButtons();
  resetResultsTable();
  showWizardStep("step-1", event);
}

function resetResultsTable() {
  months.map(m => {
    var monthResult = document.getElementById(m.toLowerCase());
    monthResult.querySelector(".results__item__irrigation").innerText = "";
    monthResult.classList.remove("has-irrigation");
  });
}

function setPlotSizeTexts() {
  var plotSizeTextTargets = [].slice.call(
    document.querySelectorAll("[data-plotsize-text='true']")
  );
  if (plotSizeTextTargets.length) {
    plotSizeTextTargets.forEach(function(target, index) {
      target.innerText =
        (formVariables.plotLength * formVariables.plotWidth).toFixed(2) + "m";
      var sup = document.createElement("sup");
      sup.innerText = "2";
      target.appendChild(sup);
    });
  }
}

function resetIrrigationControlButtons() {
  var irrigationControlButtons = [].slice.call(
    document.querySelectorAll("[data-irrigation-control='true']")
  );
  if (irrigationControlButtons.length) {
    irrigationControlButtons.forEach(function(button, index) {
      var controlTarget = document.getElementById(
        button.getAttribute("aria-controls")
      );
      if (controlTarget) {
        controlTarget.setAttribute("aria-hidden", true);
      }
      button.setAttribute("aria-pressed", false);
    });
  }
}

function showIrrigationMonthData(event) {
  var target = document.getElementById(
    event.target.getAttribute("aria-controls")
  );
  resetIrrigationControlButtons();
  event.target.setAttribute("aria-pressed", true);
  target.setAttribute("aria-hidden", false);
}

function updatePlotSize() {
  var lengthInput = document.getElementById("plot-length");
  var widthInput = document.getElementById("plot-width");
  if (lengthInput && widthInput) {
    formVariables.plotLength = lengthInput.value;
    formVariables.plotWidth = widthInput.value;
  }
}

function collateMonths(data) {
  // for each month, get the yearly rainfall and take the average
  var returnData = {};
  var avg = data.length;
  /*var returnData = months.reduce((acc, cur) => {
    console.log(acc, cur);
    acc[cur] = data[0][cur];
    return acc;
  }, {});
  */
  months.map(m => {
    var p = data
      .map(d => d[m])
      .reduce(
        (accumulator, currentValue) =>
          parseInt(accumulator) + parseInt(currentValue),
        0
      );
    returnData[m] = p / avg;
  });
  return returnData;
}

function calculateResults() {
  const irrg = formVariables.irrigations[formVariables.speiclass];
  calculateMonthsIrrigation(irrg.rainfall, irrg.potential, irrg.transpiration);
}

async function getNearest(lat, lng) {
  try {
    return await fetch(`/points/nearest/${lng}/${lat}`)
      .then(response => response.json())
      .then(data => {
        if (data.length) {
          var gridid = data[0].Agri4Cast;
          var points = data.filter(p => {
            return p.Agri4Cast === gridid;
          });

          return points;
        }

        console.log(`No results found for ${lng}/${lat}`);
        return [];
      });
  } catch (err) {
    console.error(err);
    //display no results
  }
}

function noResults() {
  setLocationTexts();
  window.alert(
    "Sorry, no water data is available for this location. Please try another location."
  );
}

function initMap() {
  setCurrentMonthOnResultsScreen();
  map = new google.maps.Map(document.getElementById("map"), {
    center: {
      lat,
      lng
    },
    mapTypeControl: false,
    scaleControl: true,
    zoomControl: true,
    streetViewControl: false,
    zoom: 12
  });

  var input = document.getElementById("pac-input");
  var searchBox = new google.maps.places.SearchBox(input);

  map.addListener("bounds_changed", function() {
    searchBox.setBounds(map.getBounds());
  });

  searchBox.addListener("places_changed", function() {
    var places = searchBox.getPlaces();

    if (places.length === 0) {
      selectLocationText = "Select location";
      return;
    }

    clearMarkers();

    // For each place, get the icon, name and location.
    var bounds = new google.maps.LatLngBounds();

    selectLocationText = places.length
      ? "Nr. " + places[0].formatted_address
      : "Select location";

    places.forEach(function(place) {
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  });

  checkCanSelectCoords();

  setPlotSizeTexts();
}

export {
  formVariables,
  initMap,
  showWizardStep,
  showIrrigationMonthData,
  selectSpeiClass,
  backToStep1
};
